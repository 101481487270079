import { Inter } from "next/font/google";

const inter = Inter({ subsets: ["latin"] });
import { Metadata } from "next";
import Link from "next/link";
import type { NextPage } from "next";
import { useTranslation } from "next-i18next";
import { NextSeo } from "next-seo";
import Head from "next/head";
import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { FaApple } from "react-icons/fa";
import { useRecoilState } from "recoil";
import { authFormState } from "../Atoms/auth/AuthFormState";
import AuthCard from "../components/auth/AuthCard";
import { API_URL, AppConfig } from "../config";
import { useToast } from "@/components/ui/use-toast";
import { getSession } from "next-auth/react";
import axios from "axios";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar";
import { useDarkTheme } from "@/contexts/DarkTheme";
import { cn } from "@/lib/utils";
import FavIconsMeta from "@/components/FavIconsMeta";
import GoogleAnalytics from "@/components/GA";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { BsGlobe } from "react-icons/bs";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";

const Home: NextPage = (props: any) => {
  const router = useRouter();
  const { toast } = useToast();
  const [showLanguageMenu, setShowLanguageMenu] = useState<boolean>(false);
  const [enabledSwitch, setEnabledSwitch] = useState(
    router.query.locale || "en"
  );
  const [formState, setFormState] = useRecoilState(authFormState);
  const { darkTheme } = useDarkTheme();
  const { t } = useTranslation("common");
  const { t: ct } = useTranslation("layout");
  let count = 0;
  const selectedLanguage = useMemo(
    () => router.query.locale || enabledSwitch,
    [router.query.locale, enabledSwitch]
  );
  const languagesOptions = useMemo(() => {
    return [
      {
        name: ct("header.chinese"),
        value: "zh",
      },
      {
        name: ct("header.english"),
        value: "en",
      },
      {
        name: ct("header.german"),
        value: "de",
      },
      {
        name: ct("header.italian"),
        value: "it",
      },
      {
        name: ct("header.portuguese"),
        value: "pt",
      },
      {
        name: ct("header.spanish"),
        value: "es",
      },
    ];
  }, [t]);

  useEffect(() => {
    if (props?.user !== null) {
      if (localStorage && props?.user?.user) {
        localStorage.setItem("user-info", JSON.stringify(props?.user));
        localStorage.setItem("userAuthenticated", "true");
        if (
          props?.user.subscription &&
          props?.user.subscription == "inactive"
        ) {
          router.push(`/subscription?locale=${router.query.locale || "en"}`);
        }
        router.push(`/build?locale=${router.query.locale || "en"}`);
      }
    }
  }, []);
  const toastMessage = (status: boolean, message: string) => {
    if (status == true) {
      toast({
        title: message,
      });
      return;
    } else {
      toast({
        title: message,
        variant: "destructive",
      });
    }
    // throw new Error(message);
  };
  const custom_button = (
    <div className="flex justify-center">
      <div className="flex lg:flex-row flex-col gap-4 mt-4">
        <a
          target="_blank"
          rel="noreferrer"
          className="button flex items-center font-semibold border border-primary p-1 rounded-sm"
          href="https://apps.apple.com/in/app/bytemine/id1568477262"
        >
          <FaApple className="mr-2" size={35} />
          <span className="flex flex-col  text-2xl">
            <span className="text-xs font-normal">
              {t("index.availableOnThe")}
            </span>
            {t("index.appStore")}
          </span>
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          className="button flex items-center font-semibold border border-primary p-1 rounded-sm"
          href="https://play.google.com/store/apps/details?id=com.bytemine&hl=en_IN&gl=US"
        >
          <Image src="/images/playstore.png" height={30} width={30} alt="" />
          <span className="flex flex-col ml-2 text-2xl">
            <span className="text-xs font-normal">{t("index.getItOn")}</span>
            {t("index.googlePlay")}
          </span>
        </a>
      </div>
    </div>
  );
  return (
    <>
      <Head>
        {process.env.NODE_ENV === "production" ? (
          <meta name="robots" content="index,follow" />
        ) : (
          <meta name="robots" content="noindex,nofollow" />
        )}
        <GoogleAnalytics GA_TRACKING_ID={"G-NRPKERR6KT"} />
        <meta charSet="UTF-8" key="charset" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
          key="viewport"
        />
        {/* <link rel="manifest" href={`${router.basePath}/site.webmanifest`} /> */}
        {/* <link
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
          rel="stylesheet"
        /> */}
        {/* <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" /> */}
        <FavIconsMeta />
        <link
          rel="stylesheet"
          href="https://rsms.me/inter/inter.css"
          key="inter-font"
        />
      </Head>
      <NextSeo
        title={AppConfig.title}
        description={AppConfig.description}
        openGraph={{
          locale: AppConfig.locale,
          site_name: AppConfig.site_name,
        }}
      />
      <div className="relative ">
        <div className="flex absolute z-10 right-1 top-1 justify-center items-center" id="languageMenu">
          <DropdownMenu
            open={showLanguageMenu}
            onOpenChange={(open) => setShowLanguageMenu(open)}
          >
            <DropdownMenuTrigger asChild>
              <div className="flex flex-col">
                <BsGlobe
                  className="cursor-pointer"
                  size={12}
                  onClick={() => {
                    setShowLanguageMenu(true);
                  }}
                />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent className="w-52" sideOffset={20}>
              <DropdownMenuLabel>
                {ct("header.PlatformLanguage")}
              </DropdownMenuLabel>
              <DropdownMenuSeparator />
              <DropdownMenuGroup>
                <div className="flex flex-col gap-2 px-2">
                  {languagesOptions.map((lang, index) => {
                    const isSelected = lang.value === selectedLanguage;
                    return (
                      <div
                        className="flex flex-row items-center justify-between gap-2"
                        key={index}
                      >
                        <Label htmlFor={"lang-box-" + lang.value}>
                          {lang.name}
                        </Label>
                        <Switch
                          id={"lang-box-" + lang.value}
                          checked={isSelected}
                          disabled={isSelected}
                          onCheckedChange={() => {
                            setEnabledSwitch(lang?.value || "en");
                            router.query.locale = lang?.value || "en";
                            router.push(router);
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </DropdownMenuGroup>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
        <div className="container relative md:h-screen pt-[5rem] md:pt-[0px] flex-col items-center justify-center md:grid lg:max-w-none lg:grid-cols-2 lg:px-0">
          <div className="relative hidden h-full flex-col bg-muted p-10 lg:flex">
            <div className="absolute inset-0 bg-tl2-blue-extradark" />
            <div className="relative w-full justify-between z-20 flex items-center text-lg font-medium">
              <Link href="https://trylevel2.com/">
                <img
                  src={"/images/logos/Logo_Dark.png"}
                  className="md:max-h-[30px] xs:max-h-[25px] max-h-[25px] sm:max-w-[300px]"
                  alt=""
                />
              </Link>
              {/* Level2 */}
            </div>
            <div className="relative z-20 flex justify-center">
              <blockquote className="space-y-2">
                {/* <p className="text-lg">
                &ldquo;This library has saved me countless hours of work and
                helped me deliver stunning designs to my clients faster than
                ever before.&rdquo;
              </p> */}
                {/* <footer className="text-sm">Sofia Davis</footer> */}
              </blockquote>
            </div>
          </div>
          <div className="lg:p-8">
            <div
              className={cn(
                "mx-auto flex w-full flex-col justify-center space-y-6 sm:w-[350px]",
                formState.view == "create" ? "sm:w-[650px]" : ""
              )}
            >
              <Link href="https://trylevel2.com/">
                <Avatar className="rounded-none mx-auto w-fit h-fit">
                  <AvatarImage
                    src={
                      darkTheme
                        ? "/images/logos/Logo_Dark.png"
                        : "/images/logos/Logo_Light-2.png"
                    }
                    className="aspect-auto max-h-10 sm:max-w-[300px] flex lg:hidden object-contain"
                    alt=""
                  />
                  <AvatarFallback className="text-3xl font-bold bg-transparent">
                    Level2
                  </AvatarFallback>
                </Avatar>
              </Link>
              <div className="flex flex-col space-y-2 text-center">
                <h1 className="text-2xl font-semibold tracking-tight">
                  {formState.view == "login"
                    ? t("Sign in to your account")
                    : formState.view == "resetPassword"
                    ? t("Reset your password")
                    : formState.view == "create"
                    ? t("Create an account")
                    : t("Verify your login attempt")}
                </h1>
                {/* <p className="text-sm text-muted-foreground">
                Enter your email below to create your account
              </p> */}
              </div>
              <AuthCard toast={toastMessage} />
              {/* <UserAuthForm /> */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const getServerSideProps = async (ctx: any) => {
  let session: any = await getSession(ctx);
  let res = null;
  if (session) {
    let url = new URL("social_login", API_URL);
    url.searchParams.append("token", session.token.access_token);

    res = await axios
      .get(url.toString())
      .catch((e) => {
        console.error(e);
        return { data: {} };
      })
      .finally(() => ({ data: {} }));
  }
  return {
    props: {
      user: res?.data || null,
      ...(await serverSideTranslations(ctx.query.locale || "en", [
        "common",
        "layout",
      ])),
    },
  };
};

export default Home;
